import React from 'react';

const ComingSoon = () => {
  return (
    <div>
      <iframe
        src='https://coming-soon.danielweb.com/'
        width={'100%'}
        height={'700px'}
        className='min-h-screen cs-iframe '
        frameborder='0'
      ></iframe>
    </div>
  );
};

export default ComingSoon;
