const { gql, ApolloClient, InMemoryCache } = require("@apollo/client");
const prettier = require("prettier");
const fs = require("fs");
const config = require("../package.json");

function createApolloClient(url) {
  return new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
  });
}

/**
 * createFile
 */

async function createFile(file, process, directory, location, verbose = false) {
  try {
    mkdirp(directory);
    verbose && console.log(`[${process}] Created directory ${directory}`);
    await promiseToWriteFile(location, file);
    verbose &&
      console.log(`[${process}] Successfully wrote file to ${location}`);
  } catch (e) {
    throw new Error(`[${process}] Failed to create file: ${e.message}`);
  }
}

/**
 * promiseToWriteFile
 */

function promiseToWriteFile(location, content) {
  return new Promise((resolve, reject) => {
    fs.writeFile(location, content, (err) => {
      if (err) {
        reject(err);
        return;
      }
      resolve();
    });
  });
}

/**
 * decodeHtmlEntities
 */

function decodeHtmlEntities(text) {
  if (typeof text !== "string") {
    throw new Error(
      `Failed to decode HTML entity: invalid type ${typeof text}`
    );
  }

  let decoded = text;

  const entities = {
    "&amp;": "\u0026",
    "&quot;": "\u0022",
    "&#039;": "\u0027",
  };

  return decoded?.replace(/&amp;|&quot;|&#039;/g, (char) => entities[char]);
}

/**
 * mkdirp
 */

function mkdirp(directory) {
  const split = directory.split("/");
  let temp = ".";

  split.forEach((dir) => {
    temp = `${temp}/${dir}`;

    if (!fs.existsSync(temp)) {
      fs.mkdirSync(temp);
    }
  });
}

function getCurrentYear() {
  return new Date().getFullYear();
}

/**
 * getSiteMetadata
 */

async function getSiteMetadata(apolloClient, process, verbose = false) {
  const query = gql`
    {
      generalSettings {
        description
        language
        title
      }
    }
  `;

  let metadata = {};

  try {
    const data = await apolloClient.query({ query });
    metadata = { ...data.data.generalSettings };

    if (!metadata.language || metadata.language === "") {
      metadata.language = "en";
    } else {
      metadata.language = metadata.language.split("_")[0];
    }

    verbose &&
      console.log(
        `[${process}] Successfully fetched metadata from ${apolloClient.link.options.uri}`
      );
    return {
      metadata,
    };
  } catch (e) {
    throw new Error(
      `[${process}] Failed to fetch metadata from ${apolloClient.link.options.uri}: ${e.message}`
    );
  }
}

/**
 * getFeedData
 */

async function getSitemapData(apolloClient, process, verbose = false) {
  const posts = await getAllPosts(apolloClient, process, verbose);
  const pages = await getPages(apolloClient, process, verbose);

  return {
    ...posts,
    ...pages,
  };
}

/**
 * getSitePages
 */

async function getPages(apolloClient, process, verbose = false) {
  const query = gql`
    {
      pages(first: 10000) {
        edges {
          node {
            slug
            modified
          }
        }
      }
    }
  `;

  let pages = [];

  try {
    const data = await apolloClient.query({ query });
    pages = [
      ...data.data.pages.edges.map(({ node = {} }) => {
        return {
          slug: node.slug,
          modified: node.modified,
        };
      }),
    ];

    verbose &&
      console.log(
        `[${process}] Successfully fetched page slugs from ${apolloClient.link.options.uri}`
      );
    return {
      pages,
    };
  } catch (e) {
    throw new Error(
      `[${process}] Failed to fetch page slugs from ${apolloClient.link.options.uri}: ${e.message}`
    );
  }
}

/**
 * getFeedData
 */

async function getFeedData(apolloClient, process, verbose = false) {
  const metadata = await getSiteMetadata(apolloClient, process, verbose);
  const posts = await getAllPosts(apolloClient, process, verbose);

  return {
    ...metadata,
    ...posts,
  };
}

/**
 * removeLastTrailingSlash
 */

function removeLastTrailingSlash(url) {
  if (typeof url !== "string") return url;
  return url?.replace(/\/$/, "");
}

function removeExtraSpaces(text) {
  if (typeof text !== "string") return;
  return text?.replace(/\s+/g, " ").trim();
}

function trimExcerpt(text, maxLength = 180) {
  if (typeof text !== "string") return "";
  if (text.length < maxLength - 1) return text;
  return `${text?.replace(/(<([^>]+)>)/gi, "").slice(0, maxLength)}...`;
}

function generateSitemap({ posts = [], pages = [] }, nextConfig = {}) {
  const { homepage = "" } = config;
  const { trailingSlash } = nextConfig;

  const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <url>
        <loc>${homepage}</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
      </url>
        ${pages
          .map((page) => {
            return `<url>
                      <loc>${homepage}/${page.slug}${
              trailingSlash ? "/" : ""
            }</loc>
                      <priority>0.3</priority>
                      <lastmod>${new Date(
                        page.modified
                      ).toISOString()}</lastmod>
                    </url>
                `;
          })
          .join("")}
          ${posts
            .map((post) => {
              return `<url>
                        <loc>${homepage}/posts/${post.slug}${
                trailingSlash ? "/" : ""
              }</loc>
                        <lastmod>${new Date(
                          post.modified
                        ).toISOString()}</lastmod>
                      </url>
                  `;
            })
            .join("")}
    </urlset>
    `;

  const sitemapFormatted = prettier.format(sitemap, {
    printWidth: 120,
    parser: "html",
  });

  return sitemapFormatted;
}

/**
 * resolvePathname
 */

function resolvePublicPathname(outputDirectory, outputName) {
  const directory = outputDirectory.split("/");
  const index = directory.indexOf("public");
  const path = directory
    .map((path, i) => {
      // If actual folder is a 'public' direct subfolder and is not empty, add to pathname
      if (i > index && path) {
        return `/${path}`;
      }
    })
    .join("");

  return `${path}/${outputName}`;
}

/**
 * generateRobotsTxt
 */

async function generateRobotsTxt({ outputDirectory, outputName }) {
  const { homepage = "" } = config;

  try {
    // Build sitemap URL at root directory
    let sitemapUrl = new URL(outputName, homepage);

    // Check if output directory is not root directory
    if (outputDirectory !== "./public") {
      // Check if output directory is within './public' folder
      if (outputDirectory.startsWith("./public")) {
        // Update sitemap URL with new directory
        sitemapUrl.pathname = resolvePublicPathname(
          outputDirectory,
          outputName
        );
      } else {
        throw new Error("Sitemap should be within ./public folder.");
      }
    }

    // Robots content using sitemap final URL
    const robots = `User-agent: *\nSitemap: ${sitemapUrl}`;

    // Create robots.txt always at root directory
    await createFile(robots, "Robots.txt", "./public", "./public/robots.txt");
  } catch (e) {
    throw new Error(`[Robots.txt] Failed to create robots.txt: ${e.message}`);
  }
}

/**
 * getAllPosts
 */

async function getAllPosts(apolloClient, process, verbose = false) {
  const query = gql`
    {
      posts(first: 10000) {
        edges {
          node {
            title
            excerpt
            databaseId
            slug
            date
            modified
            author {
              node {
                name
              }
            }
          }
        }
      }
      projects(first: 500) {
        edges {
          node {
            title
            excerpt
            databaseId
            slug
            date
            modified
          }
        }
      }
    }
  `;

  let posts = [];

  try {
    const data = await apolloClient.query({ query });
    const postNodes = [...data.data.posts.edges.map(({ node = {} }) => node)];
    const projectNodes = [
      ...data.data.projects.edges.map(({ node = {} }) => node),
    ];

    posts = [...postNodes, ...projectNodes].map((post) => {
      const data = { ...post };

      if (data.author) {
        data.author = data.author.node.name;
      }

      if (data.categories) {
        data.categories = data.categories.edges.map(({ node }) => node.name);
      }

      if (data.excerpt) {
        //Sanitize the excerpt by removing all HTML tags
        const regExHtmlTags = /(<([^>]+)>)/g;
        data.excerpt = data.excerpt?.replace(regExHtmlTags, "");
      }

      return data;
    });

    verbose &&
      console.log(
        `[${process}] Successfully fetched posts from ${apolloClient.link.options.uri}`
      );
    return {
      posts,
    };
  } catch (e) {
    throw new Error(
      `[${process}] Failed to fetch posts from ${apolloClient.link.options.uri}: ${e.message}`
    );
  }
}

function terminalColor(text, level) {
  switch (level) {
    /** green */
    case "info":
    default:
      return `\x1b[32m${text}\x1b[0m`;
    /** yellow */
    case "warn":
      return `\x1b[33m${text}\x1b[0m`;
    /** red */
    case "error":
      return `\x1b[31m${text}\x1b[0m`;
  }
}

function truncateText(text = "", maxCharacters = 10) {
  if (text.length <= maxCharacters) {
    return text;
  }

  const truncatedText = text.substr(0, maxCharacters);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");
  return truncatedText.substr(0, lastSpaceIndex) + "...";
}

module.exports = {
  createApolloClient,
  createFile,
  promiseToWriteFile,
  decodeHtmlEntities,
  mkdirp,
  getCurrentYear,
  getSiteMetadata,
  getSitemapData,
  getPages,
  getFeedData,
  removeLastTrailingSlash,
  removeExtraSpaces,
  trimExcerpt,
  generateSitemap,
  resolvePublicPathname,
  generateRobotsTxt,
  getAllPosts,
  terminalColor,
  truncateText,
};
